<template>
  <section>
    <!-- filter section  -->
    <b-card class="employee-dashboard">
      <b-row>
        <b-col>
          <h3 class="box-label">
            {{ $t('employeeStatisticsFilter') }}
          </h3>
        </b-col>
      </b-row>

      <b-row class="filter-section">
        <!-- from date -->
        <b-col
          lg="3"
          md="4"
          sm="6"
          xs="12"
        >
          <label
            class="dateLabel font-small-4"
            for="fromDate-datepicker"
          > {{ $t('fromDate') }}
          </label>
          <b-form-datepicker
            id="fromDate-datepicker"
            v-model="filter.fromDate"
            locale="ar"
            :date-format-options=" {
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
            }"
            class="mb-2"
            name="fromDate"
            @input="() => {
              getDashboardData();
            }"
          />
        </b-col>

        <!-- to date -->
        <b-col
          lg="3"
          md="4"
          sm="6"
          xs="12"
        >
          <label
            class="dateLabel font-small-4"
            for="toDate-datepicker"
          > {{ $t('toDate') }}
          </label>
          <b-form-datepicker
            id="toDate-datepicker"
            v-model="filter.toDate"
            locale="ar"
            :date-format-options=" {
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
            }"
            class="mb-2"
            name="toDate"
            @input="() => {
              getDashboardData();
            }"
          />
        </b-col>

        <!-- department Id -->
        <b-col
          lg="3"
          md="4"
          sm="6"
          xs="12"
        >
          <g-field
            :value.sync="filter.departmentId"
            :dir="isRight ? 'rtl' : 'ltr'"
            label-text="department"
            field="select"
            :options="lookups.departments"
            :label="isRight ? 'arabicName' : 'englishName'"
            @change="() => getDashboardData()"
            @input="() => { if(!filter.departmentId) getDashboardData() }"
          />
        </b-col>

        <!-- division Id -->
        <b-col
          lg="3"
          md="4"
          sm="6"
          xs="12"
        >
          <g-field
            :value.sync="filter.divisionId"
            :dir="isRight ? 'rtl' : 'ltr'"
            label-text="division"
            field="select"
            :options="lookups.divisions"
            :label="isRight ? 'arabicName' : 'englishName'"
            @change="() => getDashboardData()"
            @input="() => { if(!filter.divisionId) getDashboardData() }"
          />
        </b-col>

        <!-- custom branches -->
        <b-col
          sm="6"
          xs="12"
          class="branches"
        >
          <g-field
            :value.sync="helper.selectedBranches"
            multiple
            :dir="isRight ? 'rtl' : 'ltr'"
            label-text="branch"
            field="select"
            :clearable="false"
            :disabled="helper.isAllBranches"
            :options="branches"
            :label="isRight ? 'arabicName' : 'englishName'"
            @option:deselected="(branch) => { deselctionHandle(branch) }"
            @change="() => getDashboardData()"
          />
        </b-col>

        <!-- all branches -->
        <b-col
          lg="3"
          md="4"
          sm="6"
          xs="12"
        >
          <b-form-checkbox
            v-model="helper.isAllBranches"
            name="check-button"
            switch
            inline
            class="mb-2"
            style="margin-top: 30px;"
            @change="() => getDashboardData()"
          >
            {{ $t('allBranches') }}
          </b-form-checkbox>
        </b-col>
      </b-row>
    </b-card>

    <b-row class="employee-data">
      <b-col md="4">
        <!-- employee data  -->
        <b-card>
          <h3 class="box-label">
            {{ $t('employeesData') }}
          </h3>
          <b-row>

            <!-- all employees count -->
            <b-col md="12">
              <div
                class="employee-box-content"
                style="border-color: #3680a6; background-color: #3680a61a;"
              >
                <img
                  src="@/assets/images/custom/employee.svg"
                  width="50"
                  class="mr-1"
                >
                <div class="d-flex flex-column">
                  <label class="box-value"> {{ dashboard.status.allCount }} </label>
                  <label> {{ $t('allEmployess') }} </label>
                </div>
              </div>
            </b-col>

            <!-- active employees count -->
            <b-col md="12">
              <div
                class="employee-box-content"
                style="border-color: #59ab62; background-color: #59ab621a;"
              >
                <img
                  src="@/assets/images/custom/activeEmployee.svg"
                  width="50"
                  class="mr-1"
                >
                <div class="d-flex flex-column">
                  <label class="box-value"> {{ dashboard.status.activeCount }} </label>
                  <label> {{ $t('activeEmployees') }} </label>
                </div>
              </div>
            </b-col>

            <!-- suspended employees count -->
            <b-col md="12">
              <div
                class="employee-box-content"
                style="border-color: #c85151; background-color: #c851511a;"
              >
                <img
                  src="@/assets/images/custom/suspendedEmployee.svg"
                  width="50"
                  class="mr-1"
                >
                <div class="d-flex flex-column">
                  <label class="box-value"> {{ dashboard.status.suspendedCount }} </label>
                  <label> {{ $t('suspendedEmployees') }} </label>
                </div>
              </div>
            </b-col>
          </b-row>

        </b-card>
      </b-col>

      <b-col md="4">
        <!-- employee financial data  -->
        <b-card>
          <h3 class="box-label">
            {{ $t('employeesAccounts') }}
          </h3>
          <b-row>

            <!-- total benefits -->
            <b-col md="12">
              <div
                class="accounts-box-content mb-2"
                style="border-color:#59ab62;"
              >
                <b-avatar
                  size="65"
                  class="mr-2"
                  variant="light-success"
                >
                  <img
                    src="@/assets/images/custom/walletAdd.svg"
                    width="40"
                  >
                </b-avatar>
                <div class="d-flex flex-column">
                  <label>  {{ $t('benefits') }} </label>
                  <label class="box-value"> {{ dashboard.benefitsAndDeduction.totalBenefits }} </label>
                </div>
              </div>
            </b-col>

            <!-- total deductions -->
            <b-col md="12">
              <div
                class="accounts-box-content mb-2"
                style="border-color: #c85151;"
              >
                <b-avatar
                  size="65"
                  class="mr-2"
                  variant="light-danger"
                >
                  <img
                    src="@/assets/images/custom/walletMinus.svg"
                    width="40"
                  >
                </b-avatar>
                <div class="d-flex flex-column">
                  <label> {{ $t('Deductions') }} </label>
                  <label class="box-value"> {{ dashboard.benefitsAndDeduction.totalDeductions }} </label>
                </div>
              </div>
            </b-col>

            <!-- total advance payment -->
            <b-col md="12">
              <div
                class="accounts-box-content"
                style="border-color: #3680a6;"
              >
                <b-avatar
                  size="65"
                  class="mr-2"
                  variant="light-primary"
                >
                  <img
                    src="@/assets/images/custom/handMoney.svg"
                    width="40"
                  >
                </b-avatar>
                <div class="d-flex flex-column">
                  <label> {{ $t('advancedPayments') }} </label>
                  <label class="box-value"> {{ dashboard.benefitsAndDeduction.totalAdvancedPayments }} </label>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>

      <b-col md="4">
        <b-row>
          <b-col md="12">
            <!-- employees salaries  -->
            <b-card>
              <h3 class="box-label">
                {{ $t('employeesSalaries') }}
              </h3>
              <label
                class="d-block text-center"
                style="font-size: 35px;"
              >
                {{ dashboard.totalSalary }}
              </label>
            </b-card>
          </b-col>

          <b-col md="12">
            <!-- employee according to gender  -->
            <b-card>
              <h3 class="box-label">
                {{ $t('employeesAccordingToGender') }}
              </h3>
              <b-row>
                <!-- total male count -->
                <b-col md="6">
                  <div
                    class="gender-box-content mb-2"
                    style="border-color: #3e5ca3;"
                  >
                    <div class="d-flex flex-column">
                      <label class="box-value"> {{ dashboard.gender.maleCount }} </label>
                      <label> {{ $t('male') }} </label>
                    </div>
                    <img
                      src="@/assets/images/custom/male.svg"
                      width="70"
                      style="margin-left: -20px;"
                    >
                  </div>
                </b-col>

                <!-- total female count -->
                <b-col md="6">
                  <div
                    class="gender-box-content"
                    style="border-color: #ac78bf;"
                  >
                    <div class="d-flex flex-column">
                      <label class="box-value"> {{ dashboard.gender.femaleCount }} </label>
                      <label> {{ $t('female') }} </label>
                    </div>
                    <img
                      src="@/assets/images/custom/female.svg"
                      width="70"
                      style="margin-left: -20px;"
                    >
                  </div>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </section>
</template>

<script>

export default {
  data() {
    return {
      lookups: {
        departments: [],
        divisions: []
      },

      filter: {
        branchIds: null,
        fromDate: null,
        toDate: null,
        managementId: null,
        divisionId: null
      },

      dashboard: {
        totalSalary: 0,
        status: {
          allCount: 0,
          activeCount: 0,
          suspendedCount: 0
        },
        gender: {
          maleCount: 0,
          femaleCount: 0
        },
        benefitsAndDeduction: {
          totalBenefits: 0,
          totalDeductions: 0,
          totalAdvancedPayments: 0
        }
      },
      helper: {
        isAllBranches: false,
        selectedBranches: []
      }
    }
  },

  mounted() {
    this.filter.fromDate = this.getDate(this.currentYear.startDate);
    this.filter.toDate = this.getDate(this.currentYear.endDate);
    this.helper.selectedBranches.push(this.currentBranch);
    this.getDashboardData();
    this.filter_loadLookups();
  },

  methods: {
    filter_loadLookups() {
      this.get({ url: 'departments' }).then((data) => {
        this.lookups.departments = data;
      })
      .then(() => {
        this.get({ url: 'divisions' }).then((data) => {
        this.lookups.divisions = data;
      });
      })
    },

    serialzeBranches() {
      const branchesSelected = []
      this.helper.selectedBranches.forEach(element => {
        branchesSelected.push(element.id)
      });
      return branchesSelected.toString()
    },

    getDashboardData() {
      // prepare paramters
      this.filter.branchIds = this.helper.isAllBranches ? null : this.serialzeBranches();
      var params = this.getFilterObj(this.filter);

      // then retrieve data
      this.get({ url: `Aggregates/EmployeeDashBoardData?${params}` }).then((data) => {
        // ----- dealing with Employees Count Per Status ------
        if (data.employeesCountPerStatus) {
          const activeEmployees = data.employeesCountPerStatus.find((x) => x.status === 'active');
          const suspendedEmployees = data.employeesCountPerStatus.find((x) => x.status === 'suspended');
          this.dashboard.status.activeCount = activeEmployees ? activeEmployees.countPerStatus : 0;
          this.dashboard.status.suspendedCount = suspendedEmployees ? suspendedEmployees.countPerStatus : 0;
          this.dashboard.status.allCount = this.dashboard.status.activeCount + this.dashboard.status.suspendedCount;
        }

        // ----- dealing with Employees Count Per Gender ------
        if (data.employeesCountPerGender) {
          const maleEmployees = data.employeesCountPerGender.find((x) => x.gender === 'male');
          const femaleEmployees = data.employeesCountPerGender.find((x) => x.gender === 'female');
          this.dashboard.gender.maleCount = maleEmployees ? maleEmployees.countPerGender : 0;
          this.dashboard.gender.femaleCount = femaleEmployees ? femaleEmployees.countPerGender : 0;
        }

        // ----- dealing with Employees Total Salary ------
        if (data.employeesTotalSalary) {
          this.dashboard.totalSalary = data.employeesTotalSalary.totalSalary.toLocaleString();
        }

        // ----- dealing with Employees Total Benefits And Deductions Per Type ------
        if (data.employeesTotalBenefitsAndDeductionsPerType) {
          const totalBenefits = data.employeesTotalBenefitsAndDeductionsPerType.find((t) => t.type === 'benefits');
          const totalDeductions = data.employeesTotalBenefitsAndDeductionsPerType.find((t) => t.type === 'deductions');
          const totalAdvancedPayments = data.employeesTotalBenefitsAndDeductionsPerType.find((t) => t.type === 'advancedPayments');
          this.dashboard.benefitsAndDeduction.totalBenefits = totalBenefits ? totalBenefits.totalPerType.toLocaleString() : 0;
          this.dashboard.benefitsAndDeduction.totalDeductions = totalDeductions ? totalDeductions.totalPerType.toLocaleString() : 0;
          this.dashboard.benefitsAndDeduction.totalAdvancedPayments = totalAdvancedPayments ? totalAdvancedPayments.totalPerType.toLocaleString() : 0;
        }
      })
    },

    deselctionHandle(branch) {
      if (this.currentBranch.id === branch.id) {
        this.helper.selectedBranches.unshift(this.currentBranch);
        return;
      }
      this.getDashboardData();
    }
  }
}
</script>
<style>
.employee-dashboard .filter-section {
  background-color: #f9f9f9;
  margin: 0 0 15px;    /* margin: -15px 0 15px; */
  padding: 25px 0 5px;
  border-radius: 7px;
  /* border-top-left-radius: 0; */
  border: 2px solid #ad8884;  /* #ad8884 */
}
.employee-dashboard .btn-filter {
  margin-left: 14px;
  width: 157px;
  margin-bottom: 15px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  font-size: 16px;
}
.employee-dashboard .branches .vs__selected,
.employee-dashboard .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #ad8884 !important;
}
.employee-dashboard .custom-control-label {
  font-size: 1rem;
}

.box-label {
  border-left: 5px solid #ad8884;
  padding-left: 10px;
  margin-bottom: 2rem;
  font-weight: bold;
}

.employee-data label {
  font-size: 16px;
  font-weight: bold;
}
.employee-data .employee-box-content {
  border-radius: 6px;
  display: flex;
  padding: 1rem;
  margin-bottom: 10px;
  border: 1px solid;
}
.employee-data .accounts-box-content {
  display: flex;
  padding: 9px 20px 9px 0;
  border-right: 6px solid;
}
.employee-data .gender-box-content {
  display: flex;
  justify-content: space-between;
  padding: 10px 0 5px;
  border-bottom: 6px solid;
}
.employee-data .box-value {
  font-size: 18px;
}
</style>
